/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-balham.css";
@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";
@import "../../../node_modules/alertifyjs/build/css/alertify.min.css";
@import "../../../node_modules/alertifyjs/build/css/themes/bootstrap.min.css";

:root {
	--var-apis-banner-color: rgb(0, 65, 82);
	--var-apis-banner-text-color: #ffffffc7;
	--var-bg-color: #232323;
	--var-header-bg-color: #232323;
	--var-sub-header-bg-color: #5e5e5e;
	--var-control-bg-color: #313131;
	--var-disabled-control-bg-color: #232323;
	--var-control-border-color: #5e5e5e;
	--var-text-color: #ced4da;
	--var-disabled-text-color: #5e5e5e;
	--var-placeholder-text-color: #6c757d;
	--var-footer-bg-color: #5e5e5e;

	--var-grid-even-row-color: #404040;
	--var-grid-odd-row-color: #303030;
	--var-grid-selected-row-color: #505050;

	--var-button-secondary-hover-color: #3e3e3e;
	--var-alert-color: #fc1121be;
	--var-alert-control-color: #2b2b2b;
}

body {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;	
}

body:not(.dark-mode-preloading) {
	transition: all 0.3s linear;
}

body.dark-mode {
	--var-bg-color: #232323;
	--var-apis-banner-color: rgb(0, 65, 82);
	--var-apis-banner-text-color: #ffffffc7;
	--var-header-bg-color: #232323;
	--var-sub-header-bg-color: #5e5e5e;
	--var-control-bg-color: #313131;
	--var-disabled-control-bg-color: #232323;
	--var-control-border-color: #5e5e5e;
	--var-text-color: #ced4da;
	--var-disabled-text-color: #5e5e5e;
	--var-placeholder-text-color: #6c757d;
	--var-footer-bg-color: #5e5e5e;
	--var-control-icon-color: white;

	--var-grid-even-row-color: #404040;
	--var-grid-odd-row-color: #303030;
	--var-grid-selected-row-color: #505050;

	--var-button-secondary-hover-color: #3e3e3e;
	--var-alert-color: #fc1121be;
	--var-alert-control-color: #2b2b2b;

	--var-menu-item-hover-color: #155083;
	--var-menu-item-text-color: #ced4da;
	--var-menu-item-text-hover-color: #ced4da;
	--var-menu-item-bg-color: #313131;
	--var-menu-selected-item-text-color: #ced4da;
	--var-menu-selected-item-bg-color: #00457a;
	--var-menu-selected-item-border-color: #0070C4;
	
	background-color: var(--var-bg-color) !important;
	color: var(--var-text-color) !important;
}

body.light-mode {
	--var-bg-color: white;
	--var-apis-banner-color: rgba(0, 129, 162, 1);
	--var-apis-banner-text-color: #FFFFFF;
	--var-header-bg-color: #F8F8F8;
	--var-sub-header-bg-color: lightgray;
	--var-control-bg-color: white;
	--var-disabled-control-bg-color: #e9ecef;
	--var-control-border-color: #ced4da;
	--var-text-color: #333333;
	--var-disabled-text-color: #a5a5a5;
	--var-placeholder-text-color: #adb1b6;
	--var-footer-bg-color: #f1f1f1;
	--var-control-icon-color: black;

	--var-grid-even-row-color: #ffffff;
	--var-grid-odd-row-color: #f1f1f1;
	--var-grid-selected-row-color: #e1e1e1;

	--var-button-secondary-hover-color: #F2F2F2;
	--var-alert-color: #fc1121;
	--var-alert-control-color: #f5f5f5;

	--var-menu-item-hover-color: #155083;
	--var-menu-item-text-color: #333333;
	--var-menu-item-text-hover-color: white;
	--var-menu-item-bg-color: #F2F2F2;
	--var-menu-selected-item-text-color: white;
	--var-menu-selected-item-bg-color: #3488c9;
	--var-menu-selected-item-border-color: #0070C4;

	background-color: var(--var-bg-color) !important;
	color: var(--var-text-color) !important;
}

hr {
    background-color: #F1F1F1;
    height: 3px;
    margin: 3rem 0rem;
    border: none;
}

h1,h2,h3,h4,h5,h6 {
	color: var(--var-text-color) !important;
}

button {
	color: #FFFFFF;
	font-weight: 600 !important;
	font-size: 16px !important;
	border: none;
	border-radius: 5px;
	background-color: #1999C7;	 
	outline: none;
	box-shadow: none;
	display: inline-block;
	padding-top: 15px;
	padding-bottom: 19px;
}
	
button:hover {
	background-color: rgba(23, 118, 159, 1);
	cursor: pointer;
}

.screen-container {
	width: 726px;
	height: 100%;
	align-items: center;
	justify-content: center;
	display: flex;		
}

.logins {
	display: flex;
	flex-direction: column;
}

.logins-sub-header {
	display: flex;
	
	align-items: center;
	font-size: 22px;
	height: 237px;
	font-weight: 400;
    font-style: normal;
    font-size: 36px;
    color: var(--var-control-bg-color);
}

.card-body {
	padding: 0.75rem;
}

.bs-datepicker-container {
	background-color: var(--var-bg-color);
}

.bs-datepicker-body table td {
	color: var(--var-text-color);
}

.bs-datepicker-body table td:hover {
	color: #333333;
}

.bs-datepicker-body {
	border-color: var(--var-control-border-color) !important;
}

.bs-datepicker {
	transform: scale(1.2);
	transform-origin: top;
}

@media (min-width: 810px) {
	.card {
		width: 790px !important;
	}
}


@media (min-width: 576px) {
	h6 {				
		line-height: 28px !important;		
	}

	.card-body {
		padding: 1.25rem;
	}
}

.card {
	background-color: var(--var-control-bg-color) !important;
	border-color: var(--var-control-border-color) !important;
}

.card-header {
	background-color: var(--var-header-bg-color) !important;
	border-color: var(--var-control-border-color) !important;
	color: var(--var-text-color) !important;
	font-size: 18px !important;
	font-weight: 400 !important;
	font-family: 'acumin-pro', sans-serif !important;
	margin: 3px 0 0 !important;
}

.info-card {
    border-style: none;
	background-color: var(--var-bg-color) !important;
}

.form-check-label {    
	margin-top: 3px !important;
	font-family: 'acumin-pro' !important;
    font-weight: 400 !important;
    font-style: normal !important;
    font-size: 14px !important;
    color: var(--var-text-color) !important;
}

.section-header {
	font-size: 18px;
	font-family: 'acumin-pro';
    font-weight: 400;
	font-style: normal;
	color: var(--var-text-color) !important;
	margin-right: 6px;
}

.section-label {
	font-family: 'acumin-pro' !important;
    font-weight: 400 !important;
    font-style: normal !important;
    font-size: 14px !important;
    color: var(--var-text-color) !important;
	margin-bottom: 0.6rem !important; 
}

.section-hint {
	font-size: 13px; 
	color: var(--var-text-color) !important;
}

.label-hint {
	font-size: 13px; 
	color: var(--var-text-color) !important;
	font-weight: 400;
}

label {
	font-family: 'acumin-pro' !important;
    font-weight: bold !important;
    font-style: normal !important;
    font-size: 15px !important;
    margin-bottom: 0.6rem !important; 
	color: var(--var-text-color) !important;
}

input::placeholder {
	font-size: 16px !important;
	color: var(--var-placeholder-text-color) !important;
}

input[type=text], input[type=number], input[type=date], input[type=time] {
	border-radius: 4px !important;    
    box-shadow: none !important;
    font-family: 'acumin-pro' !important;
    font-weight: normal !important;
	font-style: normal !important;
	font-size: 16px !important;	
	padding: 3px 2px 3px 10px !important;
	height: 56px !important;
	background-color: var(--var-control-bg-color) !important;
	color: var(--var-text-color) !important;
}

input[type=search] {
	border-radius: 4px !important;    
    box-shadow: none !important;
    font-family: 'acumin-pro' !important;
    font-weight: normal !important;
	font-style: normal !important;
	font-size: 16px !important;	
	padding: 3px 10px 3px 10px !important;
	height: 56px !important;
	background-color: var(--var-control-bg-color) !important;
	color: var(--var-text-color) !important;
}

input[type=search]::-webkit-search-cancel-button {
	-webkit-appearance: none;
	display: inline-block;
	width: 12px;
	height: 12px;
	margin-left: 10px;
	background:
	  linear-gradient(45deg, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 43%, var(--var-control-icon-color) 45%, var(--var-control-icon-color) 55%,rgba(0,0,0,0) 57%,rgba(0,0,0,0) 100%),
	  linear-gradient(135deg, transparent 0%,transparent 43%,var(--var-control-icon-color) 45%,var(--var-control-icon-color) 55%,transparent 57%,transparent 100%);
  }
 
input::-webkit-input-placeholder {
	font-size: 16px !important;	
}

select {
	border-radius: 4px !important;    
    box-shadow: none !important;
    font-family: 'acumin-pro' !important;
    font-weight: 400 !important;
	font-style: normal !important;
	font-size: 16px !important;   
	padding: 3px 2px 3px 10px !important;
	height: 56px !important;
	background-color: var(--var-control-bg-color) !important;
	color: var(--var-text-color) !important;
}

.form-control-multiselect
{
    border: 1px solid var(--var-control-border-color) !important;
    border-radius: 4px !important;
    font-family: "acumin-pro" !important;
    font-weight: 400 !important;
    font-style: normal !important;
    font-size: 16px !important;
    color: var(--var-text-color) !important;
	background-color: var(--var-control-bg-color) !important;
    height: 56px !important;
    padding: 15px 2px 3px 10px !important;
}

.mat-option {
    height: 28px !important; 
    font-family: "acumin-pro" !important;
    font-weight: 400 !important;
    font-style: normal !important;
    font-size: 16px !important;
    color: var(--var-text-color) !important;
	background-color: var(--var-control-bg-color) !important;
}

.mat-option-disabled {
    height: 28px !important; 
    font-family: "acumin-pro" !important;
    font-weight: 400 !important;
    font-style: normal !important;
    font-size: 16px !important;
    color: var(--var-disabled-text-color) !important;
	background-color: var(--var-control-bg-color) !important;
}

.mat-select-disabled {
    background-color: var(--var-disabled-control-bg-color) !important;
}

.mat-select-value {
	color: var(--var-text-color) !important;
}

textarea {
	color: var(--var-text-color) !important;
}

.mat-select-placeholder {
	font-style: italic;
	color: var(--var-placeholder-text-color) !important;
}

table {
	color: var(--var-text-color) !important;
}

.form-check-input, input[type=checkbox] {
	height: 1.25rem;
	width: 1.25rem;
}

.form-check-label {
	margin-top: 5px !important;
	margin-left: 5px !important;
}

.button-secondary {
	font-family: 'acumin-pro';
    font-weight: 700;
    font-style: normal;
    font-size: 16px;
    color: #0070C4 !important;
    padding: 11px 13px;
    border-radius: 4px;
    border: 2px solid #0070C4;
	background-color: var(--var-control-bg-color);
	text-decoration: none !important;
	outline: none !important;
	text-align: center;	
}

.button-secondary:hover {
	background-color: var(--var-button-secondary-hover-color) !important;
}

.button-primary {
	background-color: #0070C4;    
	border-radius: 4px;
	font-weight: 700;
    font-style: normal;
    font-size: 16px;
	color: #FAFAFA !important;
	text-decoration: none !important;
	padding: 12px 13px;
	outline: none !important;
	text-align: center;	
}

.button-primary:hover {
	background-color: #004F84;	
}

.button-secondary-outline {
	font-family: 'acumin-pro';
    font-weight: 700;
    font-style: normal;
    font-size: 16px;
    color: #0070C4 !important;
    padding: 8px 8px;
    border-radius: 4px;
    border: 2px solid #0070C4;
	background-color: var(--var-control-bg-color);
	text-decoration: none !important;
	outline: none !important;	
}

.button-secondary-outline:hover {
	background-color: var(--var-button-secondary-hover-color) !important;
}

.custom-control-label {
	outline: none;
}

.custom-control-label::before {    
    top: 0rem !important;   
	width: 1.25rem;
    height: 1.25rem; 
	border-color: rgba(102, 102, 102, 1) !important;
}

.custom-control-label::after {    
    top: 0rem !important;  
	width: 1.25rem;
    height: 1.25rem;
}

.custom-control-input:checked~.custom-control-label::before {
    color: #fff;
    border-color: #0070C4 !important;
    background-color: #0070C4 !important;
}

.custom-radio > .custom-control-label {
	padding-top: 0.2rem;
	padding-left: 0.5rem;
}

.custom-radio > .custom-control-label::before {
	width: 1.75rem;
	height: 1.75rem;
}

.custom-radio > .custom-control-label::after {
	width: 1.75rem;
	height: 1.75rem;
}

.required:after {
    content:" (Required)";
	font-size: 13px; 
	color: var(--var-text-color);
}
 
.callout
{
	background-color: var(--var-alert-control-color);
}  

.callout .callout-logo
{
	background-color: var(--var-alert-color);
	display: flex;
	justify-content: center;
	border-radius: 2px;
}

.error {
	color: var(--var-alert-color);
	font-size: 11.2px;
	margin-top: 2px;
}

.form-control.is-invalid{
    background-image: none;
	border-color: var(--var-alert-color) !important;
}

.form-control {
	border-color: var(--var-control-border-color) !important;
	background-color: var(--var-control-bg-color) !important;
}

.form-control:disabled, .form-control[readonly] {
	background-color: var(--var-disabled-control-bg-color) !important;
	color: var(--var-disabled-text-color) !important;
}

.form-control::placeholder {
	font-style: italic;
}

.callout .callout-logo-info
{
	background-color: #0070C4;
	display: flex;
	justify-content: center;
	border-radius: 2px;
} 

.callout .callout-logo-success
{
	background-color: #198754;
	display: flex;
	justify-content: center;
	border-radius: 2px;
} 

a.lnk-back {
	font-size: 16px;
}

a.lnk-back:before {
	margin-right: 8px;
	content: url("assets/images/left-arrow.svg");
}

.print-icon {
    cursor: pointer;
    background-image: url("assets/images/print-icon.svg");
    background-position: center;
	background-repeat: no-repeat;
	background-size: 3em;
    min-width: 56px;
    min-height: 56px;
}

.print-one-icon {
    cursor: pointer;
    background-image: url("assets/images/print-one-icon.svg");
    background-position: center;
	background-repeat: no-repeat;
	background-size: 3em;
    min-width: 56px;
    min-height: 56px;
}

.small-print-icon {
	cursor: pointer;
	background-image: url("assets/images/printer.svg");
	background-position: center;
	background-repeat: no-repeat;
	width: 24px;
	height: 22px;
}

.custom-today-class {
    background-color: var(--var-sub-header-bg-color);
}

.health-message {
    color: var(--var-alert-color);
    font-size: 16px;
}

/* This code is for toogling between @media screen and @media print mode specifically for Intake Submission Report */
/*#################################################################################################################*/
.submission-report-header, .submission-print {
	/* hide the printing component from @media screen */
	display: none;
}

@media print {
    /* invert the display (show/hide) properties of the main */
    /* application component and the printing component       */

	body {
		position:unset;
	}

    .submission-report-header, .submission-print {
        display: block;

		// it is important that the parent element doesn't have a float as this definitely breaks
		// the css page breaking 
		float: none !important;
    }

    .submissionMain, .intake-footer, .submission-report-header, .contravention-sub-header, .intake-stepper, stepper, intake-header, app-system-name-header {
        display: none;
    }

}

@media print and (max-width: 600px) {

	[class*="column-group-"] {
		width: 100%;
	}
	
}

/*##################################################################################################################*/